import { useContext } from 'react';
import { useTranslation } from '../i18n';
import { encodeHtml, getUniqUsers, lightenColor } from '../utils';
import Avatar from './avatar';
import GlobalContext from './GlobalContext';
import Link from './link';

const getUserDisplayName = user => {
  if (!user) return '';
  const parts = user.name.split(' ').filter(v => v.trim());
  if (parts.length > 1) {
    parts[parts.length - 1] = `${parts[parts.length - 1][0]}.`;
  }
  return parts.join(' ');
};

function CollectionCard({ _id, icon, title, spoiler, articles, slug }) {
  const { desk } = useContext(GlobalContext);
  const { t } = useTranslation(['common']);
  const users = getUniqUsers(
    articles
      .filter(a => a.article?.user)
      .map(a => a.article.user)
      .filter(u => !!u),
  );
  const writtenByText = t(`writtenByLabel${Math.min(users.length, 4)}`, {
    user1: encodeHtml(getUserDisplayName(users[0])),
    user2: encodeHtml(getUserDisplayName(users[1])),
    count: users.length,
  });

  return (
    <Link route="collection" params={{ _id, slug }}>
      <a className="root">
        <article>
          <i className={`icon ${icon}`} />
          <div className="info">
            <div className="top">
              <h2>{title}</h2>
              <p>{spoiler}</p>
            </div>
            <div className="bottom">
              {users.slice(0, 2).map(user => (
                <Avatar user={user} key={user.name} />
              ))}
              {users.length > 2 && <Avatar text={`+${users.length - 2}`} />}
              <div className="bottomRight lightText">
                <div>{t('numArticlesInCollection', { count: articles.length })}</div>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: writtenByText }} />
              </div>
            </div>
          </div>
        </article>
        <style jsx>
          {`
            .root {
              background: white;
              box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
              width: 100%;
              display: block;
              border-radius: 4px;
              transition: box-shadow 0.2s;
              flex: 0 0 49%;
              margin-top: 20px;
            }
            .root:hover,
            .root:focus {
              box-shadow: 0 5px 12px rgba(0, 0, 0, 0.14);
            }
            article {
              position: relative;
              color: #666;
              padding: 30px 30px 30px 100px;
              height: 100%;
            }
            .icon {
              font-size: 42px;
              position: absolute;
              left: 50px;
              top: 45%;
              transform: translate(-50%, -50%);
            }
            .info {
              height: 100%;
              display: flex;
              flex-flow: column nowrap;
              justify-content: space-between;
            }
            .info h2 {
              color: ${lightenColor(desk.color, 10)};
              margin: 0 0 3px;
              font-size: 17px;
              transition: color 0.2s;
              font-weight: 500;
            }
            .root:hover h2,
            .root:focus h2 {
              color: ${desk.color};
            }
            .info p {
              margin: 0 0 12px;
              line-height: 1.4;
              font-size: 15px;
              // https://css-tricks.com/line-clampin/
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .bottom {
              display: flex;
              flex-flow: row nowrap;
              align-items: flex-end;
            }
            .bottomRight {
              font-size: 13px;
              line-height: 1.3;
            }
            :global(.avatar) ~ .bottomRight {
              margin-left: 10px;
            }
            @media screen and (max-width: 1024px) {
              .root {
                flex: 0 0 100%;
              }
              article {
                padding: 25px 25px 25px 80px;
              }
              .icon {
                font-size: 32px;
                left: 40px;
                top: 40px;
                transform: translateX(-50%);
              }
            }
            @media screen and (max-width: 480px) {
              article {
                padding: 20px;
                height: auto;
              }
              .icon {
                position: static;
                transform: none;
                margin-bottom: 10px;
              }
              .bottom :global(.avatar:not(:first-child)) {
                display: none;
              }
            }
          `}
        </style>
      </a>
    </Link>
  );
}

export default CollectionCard;
