import CollectionCard from '../components/collectionCard';
import MetaLinks from '../components/metaLinks';
import MetaSocial from '../components/metaSocial';
import { useTranslation } from '../i18n';
import { sortBy } from '../utils';
import fetch from '../utils/fetch';

const i18nNs = ['common'];

function Home({ desk, collections }) {
  const { t } = useTranslation(i18nNs);

  return (
    <div className="root">
      <MetaLinks path="/" canonical={false} />
      <MetaSocial
        title={desk.languageFields.title}
        description={desk.languageFields.subtitle}
        type="website"
        image={desk.socialMediaImgUrl}
      />
      {collections.length === 0 && <h2 className="noResults">{t('noCollections')}</h2>}
      <div className="collections">
        {sortBy(collections, 'order')
          .filter(collection => !!collection && typeof collection === 'object')
          .map(collection => (
            <CollectionCard {...collection} key={collection._id} />
          ))}
      </div>
      <style jsx>
        {`
          .root {
            margin-top: -20px;
          }
          .noResults {
            font-size: 1.5rem;
            font-weight: 400;
          }
          .collections {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
          }
        `}
      </style>
    </div>
  );
}

Home.getInitialProps = async ctx => {
  const { query } = ctx;
  const collections = await fetch('/collections', {
    query: { language: query.language },
    ctx,
  }).then(resp => resp.json());
  return { query, collections, namespacesRequired: i18nNs };
};

export default Home;
