import Head from 'next/head';
import { useContext } from 'react';
import { getBasePath, getBaseUrl } from '../utils';
import GlobalContext from './GlobalContext';

function MetaLinks({ path, language, alternates = true, canonical = true }) {
  const { desk, query } = useContext(GlobalContext);
  language = language || desk.defaultLanguage;
  return (
    <Head>
      {alternates &&
        desk.languages.map(l => (
          <link
            key={l.language}
            rel="alternate"
            href={`${getBaseUrl(query, false)}/${l.language}${path}`}
            hrefLang={l.language}
            data-path={`${getBasePath(query, false)}/${l.language}${path}`}
          />
        ))}
      {canonical && (
        <link
          key="canonical"
          rel="canonical"
          href={`${getBaseUrl(query, false)}/${language}${path}`}
        />
      )}
    </Head>
  );
}

export default MetaLinks;
